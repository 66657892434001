import config from "utils/config";
import "./Footer.scss";
import FooterSocials from "./FooterSocials";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FeatureFlags, useFeatureFlag } from "components/FeatureFlagsHook";

const Footer = () => {
  const { t } = useTranslation();
  const isPricingEnabled = useFeatureFlag(FeatureFlags.Pricing);
  // const [selectedLanguage, setSelectedLanguage] = useState("");

  // useEffect(() => {
  //   i18n &&
  //     config.locales.forEach((lang) => {
  //       console.log(lang.value);
  //       lang.value.includes(
  //         i18n.language.split("-").length > 1
  //           ? i18n.language.split("-")[0]
  //           : i18n.language,
  //       ) && setSelectedLanguage(lang.value);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [i18n]);

  return (
    <section className="footer flex flex-wrap">
      <div className="content flex flex-wrap gap-[25px] md:flex-nowrap md:gap-[85px]">
        <div className="signature">
          <div className="logo">
            <img src={require("assets/logo.svg").default} alt="logo" />
          </div>
          <pre className="desc">
            {t(
              "improve-your-skills-faster-and-easier-nwhile-working-on-our-best-labs",
            )}
          </pre>
        </div>
        <div className="navigation flex flex-wrap md:flex-nowrap">
          <div className="footer_column">
            <div className="title">{t("company")}</div>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/about-us`}
              className="item emphasized"
            >
              {t("about-us")}
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/careers`}
              className="item emphasized"
            >
              {t("careers")}
            </Link>
            {
              isPricingEnabled && (
                <Link
                  href={`${process.env.REACT_APP_LANDING_URL}/pricing`}
                  className="item emphasized"
                >
                  {t("pricing")}
                </Link>
              )
            }
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
              className="item emphasized"
            >
              {t("privacy")}
            </Link>
          </div>
          <div className="footer_column">
            <div className="title">{t("contact")}</div>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/blog`}
              className="item"
            >
              {t("blog")}
            </Link>
            <Link href={`${process.env.REACT_APP_LANDING_URL}/community`} className="item">
              {t("community")}
            </Link>
            <Link
              onClick={(e) => {
                e.preventDefault();
                let w = window as any;
                if (w.HubSpotConversations && w.HubSpotConversations.widget) {
                  (window as any).HubSpotConversations.widget.open();
                }
              }}
              href="#"
              className="item"
            >
              {t("contact")}
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/faq`}
              className="item"
            >
              FAQ
            </Link>
          </div>
          <div className="footer_column">
            <div className="title">{t("more")}</div>
            <Link href="/?login" className="item">
              {t("join-us")}
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/tos`}
              className="item"
            >
              {t("terms-of-use")}
            </Link>
            <Link
              href="https://help.scalyz.com/en/kb"
              target="_blank"
              rel="noreferrer"
              className="item"
            >
              {t("help-center")}
            </Link>
          </div>
        </div>
        <div className="flex flex-row flex-nowrap gap-[26px]">
          <FooterSocials socials={config.footer.socials}></FooterSocials>
          {/* <TextField
            id={"locale"}
            name={"locale"}
            select
            InputProps={{
              disableUnderline: true,
              className:
                "!rounded-[10px] !border-none !border-0 !custom-text-poppins-400-16px !shadow-[0px_2px_10px_0px_rgba(0,0,0,0.10)] !h-[45px] !px-[11px] !bg-none",
              startAdornment: (
                <InputAdornment position="start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM10 18L12 16L13 15V13H11V12L10 11H7V14L9 16V17.931C5.06 17.436 2 14.072 2 10L3 11H5V9H7L10 6V4H8L7 3V2.589C8.92323 1.80531 11.0768 1.80531 13 2.589V4L12 5V7L13 8L16.13 4.87C16.8909 5.78125 17.4395 6.85043 17.736 8H16L14 10V12L15 13H17L17.286 13.286C16.029 16.061 13.239 18 10 18Z"
                      fill="#6B7280"
                    />
                  </svg>
                </InputAdornment>
              ),
            }}
            SelectProps={{
              inputProps: {
                className: "!bg-transparent",
              },
              MenuProps: {
                sx: {
                  background: "transparent",
                  "& .MuiPaper-root": {
                    borderRadius: "10px",
                    boxShadow:
                      "0px 7px 29px 0px rgba(100, 100, 111, 0.20)!important",
                  },
                },
              },
            }}
            className="!pt-[16px]"
            variant="standard"
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
              i18n.changeLanguage(e.target.value);
            }}
          >
            {config.locales.map((locale, index) => {
              return (
                <MenuItem
                  className="!custom-text-poppins-400-16px !text-black"
                  key={index}
                  selected={locale.value === selectedLanguage}
                  value={locale.value}
                >
                  {locale.label}
                </MenuItem>
              );
            })}
          </TextField> */}
        </div>
      </div>

      <div className="copyright">
        {config.footer.copyright}
        <br />
        {process.env.REACT_APP_BUILD_VERSION && process.env.NODE_ENV !== "production"
          ? `${config.footer.build_version} ${process.env.REACT_APP_BUILD_VERSION}`
          : ""}
      </div>
    </section>
  );
};

export default Footer;
