import "./_index.scss";
import Modal from "@mui/material/Modal";
import { Backdrop, FormControl, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingImage from "components/LoadingImage";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import renderers from "utils/markdown";
import ReactMarkdown from "react-markdown";

const CongratulateAfterPaymentModal = (props) => {
  const { open, data, handleClose } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="congratulations-modal"
      aria-describedby="Congratulation on finishing the lab!"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.2)",
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <div
        style={{
          outline: "none",
          display: "flex",
        }}
        className="congratulate-lab-modal"
      >
        {!data && <LoadingImage />}
        {data && (
          <div className="congratulate-lab-form-congratulate">
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                height: "100%",
              }}
            >
              <Grid item xs={12} className="z-10 !mt-[180px]">
                <p className="congratulate-lab-form-congratulate-title">
                  {t("congratulations")}!
                </p>
              </Grid>
              <Grid item xs={12} className="z-10">
                <p className="congratulate-lab-form-congratulate-title">
                  Your Payment Was Successful ✔︎
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: "6px", marginBottom: "110px" }}
              >
                <p className="congratulate-lab-form-congratulate-subtitle">
                  Your Actual Plan :{" "}
                  <span className="capitalize">{data.name}</span> /{" "}
                  {data.final_price_monthly}€ Monthly
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  gap: "13px",
                  marginLeft: "50px",
                  marginTop: "auto",
                  marginBottom: "40px",
                  zIndex: "10",
                }}
              >
                {data.features &&
                  data.features.map((feature) => (
                    <div className="inline-flex gap-[8px]">
                      <img
                        src={require("assets/tick.png")}
                        alt="Tick"
                        className="!h-[20px] !w-[20px]"
                      />
                      <ReactMarkdown
                        components={renderers}
                        rehypePlugins={[rehypeHighlight]}
                        remarkPlugins={[remarkGfm]}
                        className="!custom-text-poppins-400-24px prose prose-base max-w-none !font-poppins"
                      >
                        {feature}
                      </ReactMarkdown>
                    </div>
                  ))}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  marginTop: "auto",
                  flexDirection: "column",
                  gap: 0,
                }}
              >
                <p className="custom-text-poppins-300-13px text-[#374151]">
                  You can change or cancel your plan{" "}
                  <Link className="!no-underline" href={"/profile/manage-plan"}>
                    here
                  </Link>{" "}
                  any time!
                </p>
                <FormControl
                  fullWidth
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo(0, window.scrollY);
                      window.location.reload();
                    }}
                    className="congratulate-lab-form-confirm-btn flex justify-center text-center !no-underline hover:cursor-pointer hover:!bg-blue-dark"
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Enjoy more Labs
                      <img
                        src={require("assets/stars.png")}
                        alt={t("rocket-icon")}
                        style={{
                          marginLeft: "8px",
                          height: "20px",
                          width: "20px",
                          objectFit: "contain",
                        }}
                      />
                    </span>
                  </Link>
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                backgroundImage: `url(${require("assets/party-baloons.svg").default})`,
              }}
              className="center fixed left-[calc(50%-317px)] top-5 z-0 !h-full !max-h-[384px] !w-full !max-w-[634px]"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CongratulateAfterPaymentModal;
