import React, { createElement, Fragment } from 'react'

const components = {
  h1: ({ children, ...props }) => {
    return <h1 className="custom-text-poppins-500-16px">{children}</h1>;
  },
  h2: ({ children, ...props }) => {
    return <h2 className="custom-text-poppins-500-16px">{children}</h2>;
  },
  h3: ({ children, ...props }) => {
    return <h3 className="custom-text-poppins-500-16px">{children}</h3>;
  },
  h4: ({ children, ...props }) => {
    return <h4 className="custom-text-poppins-500-16px">{children}</h4>;
  },
  h5: ({ children, ...props }) => {
    return <h5 className="custom-text-poppins-500-16px">{children}</h5>;
  },
  h6: ({ children, ...props }) => {
    return <h6 className="custom-text-poppins-500-16px">{children}</h6>;
  },
  p: ({ children, ...props }) => {
    return <p className="custom-text-poppins-400-14px text-[#2C2C2E]">{children}</p>;
  },
  ol: ({ children }) => {
    const list = createElement(
      'ol',
      {
        className: "pl-[24px] mb-[16px] !mb-0",
      },
      children
    )
  
    return (
      <Fragment>
        {list}
      </Fragment>
    )
  },
  ul: ({ children }) => {
    const list = createElement(
      'ul',
      {
        className: "pl-[24px] mb-[16px]",
      },
      children
    )
  
    return (
      <Fragment>
        {list}
      </Fragment>
    )
  },
  li: ({ children }) => {
    return (<li className="mt-[4px] leading-[1.5] list-item">{children}</li>)
  },
  img: ({ node, ...props }) => {
    const { src, alt, title } = node.properties;
    return (
      <img
        alt={alt as string}
        src={src as string}
        title={title as string}
        style={{
          maxWidth: "100%",
          height: "auto",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
    );
  },
};

export default components;
