import "./_index.scss";
import Modal from "@mui/material/Modal";
import { Backdrop, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import SubscriptionPlansList from "./SubscriptionsList";
import LoadingImage from "components/LoadingImage";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useDebugMode } from "components/DebugContext/DebugContext";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  APIGetLabBySlug,
  APIGetSubscriptionPlanByID,
  APIGetSubscriptionPlans,
  APIGetSubscriptionsOfUser,
} from "utils/api";
import MorePlans from "./MorePlans";
import { triggerCloseModal, triggerOpenModal } from "store/slices/modals";

export enum UPGRADE_PLAN_MODAL_STATE {
  SUBSCRIPTIONS_LIST,
  CONGRATULATE,
  TRANSITIONING,
  MORE_PLANS,
}

const UpgradePlanModal = (props) => {
  const { id, open, handleClose, data } = props; //
  // data : {
  //   modal_status: UPGRADE_PLAN_MODAL_STATE;
  //   lab_slug: string;
  // }
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState();
  const [showYearly, setShowYearly] = useState(false);
  const [userSubscriptionPlan, setUserSubscriptionPlan] = useState(null);
  const user = useAppSelector((state) => (state.auth as any).user_me);
  const isLoggedIn = useAppSelector((state) => (state.auth as any).isLoggedIn);
  const [upgradePlansModalState, setUpgradePlansModalState] = useState(
    (data && data.modal_status) || UPGRADE_PLAN_MODAL_STATE.SUBSCRIPTIONS_LIST,
  );
  const { debugMode } = useDebugMode();

  const labQuery = useQuery({
    queryKey: ["lab-slug", data && data.lab_slug],
    queryFn: () => APIGetLabBySlug(data?.lab_slug),
    enabled: !!(data && data.lab_slug),
  });

  // FORMS
  const handleCheckout = async () => {
    let cbInstance = (window as any).Chargebee.getInstance();

    if (cbInstance && selectedPlan) {
      try {
        cbInstance.openCheckout({
          layout: "in_app",
          hostedPage: () => {
            return new Promise((resolve, reject) => {
              APIGetSubscriptionPlanByID(
                showYearly
                  ? (selectedPlan as any).checkout_plan_yearly_price_id
                  : (selectedPlan as any).checkout_plan_monthly_price_id,
              )
                .then((response) => {
                  return response.data;
                })
                .then((plan) => {
                  resolve(plan.checkout_url_monthly);
                })
                .catch((error) => reject(error));
            });
          },
          loaded: () => {
            console.log("Checkout loaded");
          },
          error: (error: any) => {
            console.error("Checkout error:", error);
          },
          success: () => {
            console.log("Checkout successful.");
            return new Promise((resolve, reject) => {
              APIGetSubscriptionPlanByID(
                showYearly
                  ? (selectedPlan as any).checkout_plan_yearly_price_id
                  : (selectedPlan as any).checkout_plan_monthly_price_id,
              )
                .then((response) => {
                  cbInstance.closeAll();
                  dispatch(triggerCloseModal("modal-upgrade-plan"));
                  dispatch(
                    triggerOpenModal(
                      "modal-congratulate-after-payment",
                      response.data,
                    ),
                  );
                  resolve(true);
                })
                .catch((error) => reject(error));
            });
          },
          close: () => {
            console.log("Checkout closed");
          },
          step: (currentStep) => {
            console.log("Current step:", currentStep);
          },
        });
      } catch (error) {
        console.error("Error opening checkout:", error);
      }
    }
  };
  // SWITCH MODALS
  const handleTransition = (targetStatus) => {
    setUpgradePlansModalState(UPGRADE_PLAN_MODAL_STATE.TRANSITIONING);
    setTimeout(() => {
      setUpgradePlansModalState(targetStatus);
    }, 200);
  };

  // Init Data
  useEffect(() => {
    if (labQuery.isSuccess) {
      if (
        labQuery.data.data &&
        labQuery.data.data.length > 0 &&
        labQuery.data.data[0]._upgrade_plan_to.length > 0
      ) {
        setSelectedPlan(labQuery.data.data[0]._upgrade_plan_to[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labQuery.isSuccess]);

  // init user's subscription plan:
  const subscriptions = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => APIGetSubscriptionsOfUser(user.id),
    enabled: !!user && !!user.id,
  });

  const all_sub_plans = useQuery({
    queryKey: ["subscription_plans"],
    queryFn: () => APIGetSubscriptionPlans(),
    enabled: !!user && !!user.id,
  });

  useEffect(() => {
    if (all_sub_plans.isSuccess && subscriptions.isSuccess) {
      // Map subscription plan data to an object with plan_id as key
      console.log("Getting user subscription");
      let sp = all_sub_plans.data.data.reduce((acc, o) => {
        if (!o) return acc;
        acc[o.id] = o;
        return acc;
      }, {});
      console.log("sp", sp);
      let s = subscriptions.data.data
        .filter((s) => new Date(s.end_date) > new Date())
        .map((sub: any, index: number) => sp[sub.plan_id])
        .filter((sub) => !!sub);
      console.log("subscriptions.data.data", subscriptions.data.data);
      console.log("s", s);
      if (s && s.length > 0) {
        setUserSubscriptionPlan(s[0]); // TODO: user can have multiple subscriptions
      }
      console.log("sp");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_sub_plans.isSuccess, subscriptions.isSuccess]);

  useEffect(() => {
    if (open) {
      setUpgradePlansModalState(
        (data && data.modal_status) ||
          UPGRADE_PLAN_MODAL_STATE.SUBSCRIPTIONS_LIST,
      );
    }
  }, [open, data]);

  return (
    <Modal
      open={open}
      onClose={(e) => {
        handleClose(id);
      }}
      aria-labelledby={t("auth-modal") as string}
      aria-describedby={t("signin-or-signup-to-our-platform") as string}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.2)",
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <div
        style={{
          outline: "none",
          display:
            upgradePlansModalState === UPGRADE_PLAN_MODAL_STATE.MORE_PLANS
              ? "flex"
              : "grid",
        }}
        className="upgrade-plan-modal"
      >
        {debugMode && (
          <div className="absolute top-0 flex w-full cursor-pointer flex-row justify-between rounded border-4 border-dotted border-red">
            <span className="absolute right-2 z-50 mt-6 text-xs font-thin text-red">
              debug mode
            </span>
            <Link
              onClick={() =>
                setUpgradePlansModalState(
                  UPGRADE_PLAN_MODAL_STATE.SUBSCRIPTIONS_LIST,
                )
              }
            >
              Subscription List
            </Link>
            <Link
              onClick={() =>
                setUpgradePlansModalState(UPGRADE_PLAN_MODAL_STATE.CONGRATULATE)
              }
            >
              Congratulate
            </Link>
          </div>
        )}

        <IconButton
          aria-label={t("close")}
          onClick={() => handleClose(id)}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 100,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* ---- */}

        {/* {upgradePlansModalState === UPGRADE_PLAN_MODAL_STATE.CONGRATULATE && (
          <CongratulateAfterSignup
            formik={signup_formik}
            submitError={submitError}
            setSubmitError={setSubmitError}
          />
        )} */}
        {upgradePlansModalState === UPGRADE_PLAN_MODAL_STATE.TRANSITIONING && (
          <>
            <div className="auth-header">
              <LoadingImage></LoadingImage>
            </div>
            <div className="auth-form-container">
              <div
                style={{
                  height: "100%",
                }}
                className="auth-form"
              >
                <LoadingImage />
              </div>
            </div>
          </>
        )}
        {upgradePlansModalState ===
          UPGRADE_PLAN_MODAL_STATE.SUBSCRIPTIONS_LIST && (
          <>
            <SubscriptionPlansList
              handleTransition={handleTransition}
              labQuery={labQuery}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              isLoggedIn={isLoggedIn}
              setShowYearly={setShowYearly}
              showYearly={showYearly}
              handleCheckout={handleCheckout}
            />
          </>
        )}
        {upgradePlansModalState === UPGRADE_PLAN_MODAL_STATE.MORE_PLANS && (
          <MorePlans
            subscription_plans={
              labQuery.isSuccess && labQuery.data.data.length > 0
                ? labQuery.data.data[0]._upgrade_plan_to
                : []
            }
            showYearly={showYearly}
            userSubscriptionPlan={userSubscriptionPlan}
            setSelectedPlan={setSelectedPlan}
            handleTransition={handleTransition}
          />
        )}
      </div>
    </Modal>
  );
};

export default UpgradePlanModal;
