import "./_index.scss";
import { cn } from "utils/cn";
import PricingCard from "../components/PricingCard";
import { Link } from "@mui/material";
import { UPGRADE_PLAN_MODAL_STATE } from "..";
import { getPlanPrice } from "utils/utils";

const MorePlans = (props) => {
  const {
    sectionClassName,
    subscription_plans,
    setSelectedPlan,
    handleTransition,
    showYearly,
    userSubscriptionPlan,
  } = props;

  return (
    <section
      className={cn(
        "mx-auto flex max-h-full items-center justify-center bg-white max-md:px-5",
        sectionClassName,
      )}
    >
      <div className="absolute top-0 flex h-full w-full flex-col items-center max-md:max-w-full">
        <h2 className="mt-auto pt-8 text-center font-volkhov text-[40px] font-bold text-gray-900 max-md:max-w-full">
          Simple & transparent pricing
        </h2>
        <div className="mb-[40px] mt-[15px] text-center font-poppins text-[20px] font-normal text-slate-500 max-md:max-w-full">
          {!userSubscriptionPlan && (
            <>
              Your Actual Plan : FREE / 0€ {showYearly ? "Yearly" : "Monthly"}
            </>
          )}
          {userSubscriptionPlan && (
            <>
              Your Actual Plan: {userSubscriptionPlan.name} /{" "}
              {getPlanPrice(userSubscriptionPlan, showYearly).price}€{" "}
              {showYearly ? "Yearly" : "Monthly"}
            </>
          )}
        </div>
        <>
          {subscription_plans && (
            <>
              <section className="mx-auto mb-auto max-md:max-w-full">
                <div className="flex gap-[13px] max-md:flex-col max-md:gap-8">
                  {subscription_plans.map(
                    (plan: any, index) =>
                      plan && (
                        <div
                          key={index}
                          className={`flex w-[250px] flex-col max-md:ml-0 max-md:w-full`}
                        >
                          <PricingCard
                            name={`${plan.name}`}
                            description={`${plan.description}`}
                            features={plan.features}
                            popularBadge={plan.is_recommended}
                            discountedPrice={
                              getPlanPrice(plan, showYearly).discounted_plan
                            }
                            price={getPlanPrice(plan, showYearly).price}
                            // chargebee_plan_price_id={
                            //   getPlanPrice(plan, showYearly)
                            //     .chargebee_plan_price_id
                            // }
                            showYearly={showYearly}
                            setSelectedPlan={(e) => {
                              e.preventDefault();
                              setSelectedPlan(plan);
                              console.log("Updating plan", plan);
                              handleTransition(
                                UPGRADE_PLAN_MODAL_STATE.SUBSCRIPTIONS_LIST,
                              );
                            }}
                          />
                        </div>
                      ),
                  )}
                  {/* show no items found error */}
                  {subscription_plans.length === 0 && (
                    <div className="flex w-[281px] flex-col max-md:ml-0 max-md:w-full">
                      No items found.
                    </div>
                  )}
                </div>
              </section>
            </>
          )}
        </>
        <p className="custom-text-poppins-300-13px mb-auto mt-[40px] pb-8 text-[#374151]">
          You can change or cancel your plan at any time in your{" "}
          <Link className="!no-underline" href={"/profile/manage-plan"}>
            account settings!
          </Link>{" "}
        </p>
      </div>
    </section>
  );
};

export default MorePlans;
