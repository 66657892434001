import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import renderers from "utils/markdown";
import ReactMarkdown from "react-markdown";

export interface PricingCardProps {
  price: number | null;
  discountedPrice: number | null;
  name: string;
  description: string;
  features: string[];
  popularBadge: boolean;
  showYearly: boolean;
  setSelectedPlan: any;
}

export default function PricingCard({
  price,
  discountedPrice,
  name,
  description,
  features,
  popularBadge,
  showYearly,
  setSelectedPlan,
}: PricingCardProps) {
  return (
    <div
      className={`flex w-full flex-col h-full ${
        popularBadge
          ? "rounded-3xl bg-[linear-gradient(156deg,#5DA5FA_2.21%,#255CA0_101.02%)] pb-[25px] pl-[25px] pr-[17px] pt-[17px] shadow-[0px_33px_38.5px_-7px_rgba(139,139,139,0.08)]"
          : "mx-auto grow rounded-3xl bg-white px-[25px] pb-[26px] pt-[34px] font-light shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)]"
      }`}
    >
      {popularBadge && (
        <div className="flex h-[23px] w-[121px] items-center justify-center self-end rounded-xl bg-white text-center font-poppins text-[10px] font-normal text-blue max-md:mr-2.5">
          MOST POPULAR
        </div>
      )}
      <div
        className={`flex flex-col h-full ${
          popularBadge ? "mt-1 px-6 text-white max-md:pl-5" : ""
        }`}
      >
        <div className="mx-auto flex gap-2 whitespace-nowrap">
          {discountedPrice && (
            <div
              className={`mt-1 font-poppins text-[25px] font-normal leading-10 text-[#D1D5DB] line-through`}
            >
              {discountedPrice}€
            </div>
          )}
          <div
            className={`line font-poppins text-4xl text-[36px] font-semibold leading-10 ${
              popularBadge ? "" : "text-gray-900"
            }`}
          >
            {price}€
          </div>
          <div
            className={`mt-3 self-start font-poppins text-[17px] font-medium ${
              popularBadge ? "font-medium" : "text-[#848199]"
            }`}
          >
            /{showYearly ? "year" : "month"}
          </div>
        </div>
        <div
          className={`mt-9 text-3xl ${
            popularBadge
              ? "font-poppins text-[28px] font-extrabold"
              : "font-poppins text-[28px] font-semibold text-blue"
          }`}
        >
          {name}
        </div>
        <div
          className={`mb-[15px] mt-5 font-poppins text-[14px] font-medium ${
            popularBadge ? "" : "text-[#848199] max-md:ml-2"
          }`}
        >
          {description}
        </div>
        {features.map((feature, index) => (
          <div key={index} className={`mt-[11px] flex gap-2.5 ${index === features.length - 1 ? "mb-[35px]" : ""}`}>
            {popularBadge ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  opacity="0.1"
                  d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                  fill="white"
                />
                <path
                  d="M15.7722 6.83362L10.068 14.5745C9.93197 14.7549 9.72912 14.8732 9.50503 14.9027C9.28094 14.9321 9.05441 14.8703 8.87634 14.7311L4.80301 11.4745C4.44356 11.1868 4.38536 10.6622 4.67301 10.3028C4.96066 9.94334 5.48523 9.88514 5.84468 10.1728L9.24134 12.8903L14.4305 5.84778C14.6007 5.59244 14.8974 5.45127 15.2029 5.48032C15.5083 5.50936 15.7731 5.70393 15.8921 5.98676C16.0111 6.2696 15.965 6.59494 15.7722 6.83362Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                  fill="#EAF3FF"
                />
                <path
                  d="M15.7722 6.83362L10.068 14.5745C9.93197 14.7549 9.72912 14.8732 9.50503 14.9027C9.28094 14.9321 9.05441 14.8703 8.87634 14.7311L4.80301 11.4745C4.44356 11.1868 4.38536 10.6622 4.67301 10.3028C4.96066 9.94334 5.48523 9.88514 5.84468 10.1728L9.24134 12.8903L14.4305 5.84778C14.6007 5.59244 14.8974 5.45127 15.2029 5.48032C15.5083 5.50936 15.7731 5.70393 15.8921 5.98676C16.0111 6.2696 15.965 6.59494 15.7722 6.83362Z"
                  fill="#407EC9"
                />
              </svg>
            )}
            <ReactMarkdown
              components={{
                ...renderers,
                strong: ({ children, ...props }) => {
                  return (
                    <span className={`${popularBadge ? "text-[#fff]" : ""}`}>
                      {children}
                    </span>
                  );
                },
              }}
              rehypePlugins={[rehypeHighlight]}
              remarkPlugins={[remarkGfm]}
              className={`prose prose-base my-auto max-w-none flex-auto font-poppins ${popularBadge ? "!text-[#fff]" : ""}`}
            >
              {(feature as string).split(":")[0]}
            </ReactMarkdown>
          </div>
        ))}
        <button
          className={`mt-auto h-[43px] w-[160px] justify-center self-center rounded-[18px] text-center font-poppins text-[13px] font-medium max-md:px-5 ${
            popularBadge
              ? "bg-white text-blue"
              : "bg-[linear-gradient(180deg,#245A9E_0%,#5EA6FC_100%)] text-white"
          }`}
          onClick={setSelectedPlan}
        >
          <div className={`${popularBadge ? "text-blue" : "text-white"}`}>
            <span className="z-10">Select Plan</span>
          </div>
        </button>
      </div>
    </div>
  );
}
