import "./_index.scss";
import config from "utils/config";
import { Button, FormControl, FormHelperText, Link } from "@mui/material";
import { useState } from "react";
import LoadingImage from "components/LoadingImage";
import { useTranslation } from "react-i18next";
import { UPGRADE_PLAN_MODAL_STATE } from "..";
import { getPlanPrice } from "utils/utils";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import renderers from "utils/markdown";
import ReactMarkdown from "react-markdown";

const SubscriptionPlansList = (props) => {
  const {
    labQuery,
    handleTransition,
    selectedPlan,
    isLoggedIn,
    showYearly,
    setShowYearly,
    handleCheckout,
  } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleTransitionToMorePlansModal = () => {
    handleTransition(UPGRADE_PLAN_MODAL_STATE.MORE_PLANS);
  };
  const handleTransitionToYearly = (yearly: any) => {
    setShowYearly(yearly);
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <>
      <div className="auth-header">
        <img
          src={require("assets/upgrade-illust.gif")}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.signup_modal.header.illustrationHeight,
          }}
        />
        <div className="auth-header-title">{t("why-you-should-upgrade")}</div>
        <p className="auth-header-subtitle">
          {t("by-upgrading-you-will-get-all-features-from-starter-plus")}
        </p>
        <div className="auth-header-content w-full max-w-[380px]">
          {selectedPlan &&
            selectedPlan.features &&
            selectedPlan.features.map((feature, index) => (
              <div key={index} className="auth-header-content-details">
                <img
                  src={require("assets/tick.png")}
                  alt="icon"
                  className="auth-header-content-details-icon"
                />
                <div className="auth-header-content-details-container">
                  <div className="auth-header-content-details-container-title">
                    <ReactMarkdown
                      components={renderers}
                      rehypePlugins={[rehypeHighlight]}
                      remarkPlugins={[remarkGfm]}
                      className={`prose prose-base my-auto max-w-none flex-auto font-poppins`}
                    >
                      {(feature as string).split(":")[0]}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="auth-form-container">
        <div
          style={{
            height: "fit-content",
          }}
          className="auth-form !w-full !max-w-[500px]"
        >
          <div className="subscriptions-list-modal">
            <div className="subscriptions-list-modal-title !mb-[3px]">
              {t("upgrade-now-and-take-your-nexperience-to-the-next-level")}
            </div>
            <p className="subscriptions-list-modal-subtitle custom-text-poppins-300-14px text-center md:ml-[28px]">
              {t("14-days-free-trial-included-and-no-surprise-fees")}{" "}
            </p>
            <div className="mt-12 flex gap-[13px] self-center text-center max-md:mt-10 md:ml-[80px]">
              <div className="flex whitespace-nowrap">
                <button
                  onClick={() => {
                    handleTransitionToYearly(false);
                  }}
                  className={`h-[44px] w-[111px] ${
                    showYearly
                      ? "bg-white text-[#848199] shadow-[0px_7px_31.3px_0px_rgba(100,100,111,0.09)] hover:bg-[rgb(231,231,231)]"
                      : "bg-blue text-white shadow-[0px_5px_16.6px_0px_rgba(64,126,201,0.30)] hover:bg-blue-dark"
                  } z-10 rounded-[22px] font-poppins text-[10px] font-semibold transition-colors duration-300 ease-in-out`}
                >
                  MONTHLY
                </button>
                <div className="h-[44px] w-[92px]">
                  <button
                    onClick={() => {
                      handleTransitionToYearly(true);
                    }}
                    className={`h-[44px] w-[132px] -translate-x-[40px] pr-[28.5px] ${
                      showYearly
                        ? "bg-blue text-white shadow-[0px_5px_16.6px_0px_rgba(64,126,201,0.30)] hover:bg-blue-dark"
                        : "bg-white text-[#848199] shadow-[0px_7px_31.3px_0px_rgba(100,100,111,0.09)] hover:bg-[rgb(231,231,231)]"
                    } rounded-[22px] text-right font-poppins text-[10px] font-semibold transition-colors duration-300 ease-in-out`}
                  >
                    YEARLY
                  </button>
                </div>
              </div>
              <div className="w-[5px] translate-x-[15px]">
                <div className="h-[34px] w-[33px]">
                  <img
                    alt="left-arrow"
                    className="-translate-x-[28.5px] -translate-y-[8px]"
                    src={require("assets/akar-icons_arrow-back.svg").default}
                  />
                </div>
              </div>
              <div className="my-auto h-[28px] w-[79px] content-center justify-center rounded-[14px] border border-solid border-blue bg-transparent font-poppins text-[9.5px] font-bold text-blue">
                Save -20%
              </div>
            </div>
            <div className="mt-[39px] w-full self-center">
              <div className="custom-text-poppins-400-14px mx-auto flex h-[97px] !w-full !max-w-[370px] flex-col justify-center rounded-[8px] border-[1px] border-solid border-[#407EC9] p-[10px] px-[15px] text-[#6B7280]">
                {!selectedPlan && <div>No plan selected.</div>}
                {selectedPlan && (
                  <>
                    <div>{selectedPlan.name}</div>
                    <div>
                      <span className="custom-text-poppins-500-20px text-[#111827]">
                        {getPlanPrice(selectedPlan, showYearly).price}
                      </span>
                      /mo
                    </div>
                  </>
                )}

                <Link
                  onClick={handleTransitionToMorePlansModal}
                  className="custom-text-poppins-500-14px cursor-pointer text-[#407EC9] !no-underline"
                >
                  See more plans
                </Link>
              </div>
              {selectedPlan && (
                <div className="mx-auto mt-[46px] flex max-w-[360px] flex-nowrap items-baseline">
                  {selectedPlan.name}
                  <hr className="flex-1 border-t-[2px] border-dotted border-t-[#E5E7EB]" />
                  €{getPlanPrice(selectedPlan, showYearly).price}/
                  {showYearly ? "year" : "mo"}
                </div>
              )}
            </div>
            <div className="flex w-full flex-col flex-nowrap justify-center self-center">
              <div className="custom-text-poppins-300-13px mx-auto mb-[15px] mt-[65px] w-full max-w-[418px] border-b-[1px] border-b-[#DCDCDC] pb-[4px] text-center">
                You can change or cancel your plan{" "}
                <Link
                  className="!custom-text-poppins-500-13px !no-underline"
                  href="/profile/manage-plan"
                >
                  here
                </Link>{" "}
                any time !
              </div>
              {isLoading ? (
                <LoadingImage></LoadingImage>
              ) : !isLoggedIn ? (
                <Link
                  href={`/?login&forward=${window.location.origin}${window.location.pathname}`}
                  className={`! max-md:px-5bg-[linear-gradient(180deg,#245A9E_0%,#5EA6FC_100%)] mt-[35px] flex h-[43px] w-[186px] cursor-pointer items-center justify-center self-center rounded-[18px] text-center font-poppins text-[15px] font-medium text-white`}
                >
                  <div className={`"text-white"}`}>
                    <span className="z-10">Please login first.</span>
                  </div>
                </Link>
              ) : (
                <FormControl className="!mx-auto max-w-[370px]" fullWidth>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (showYearly) {
                        handleCheckout();
                      } else {
                        handleCheckout();
                      }
                    }}
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    className="subscriptions-list-modal-btn-signin hover:!bg-blue-dark"
                    variant="contained"
                  >
                    {t("upgrade-now")}
                  </Button>
                  <FormHelperText
                    className="subscriptions-list-modal-btn-signin-error"
                    style={{ color: "#EF4444" }}
                  >
                    {labQuery.isError && labQuery.error.message}
                  </FormHelperText>
                </FormControl>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlansList;
